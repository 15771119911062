<template>
  <div v-if="equipment.value" class="d-flex align-center" style="column-gap: 8px">
    <v-icon color="success">mdi-check-circle</v-icon>
    <BaseTypography variant="subtitle-3">{{ equipment.name }}</BaseTypography>
  </div>
  <div v-else class="d-flex align-center" style="column-gap: 8px">
    <v-icon color="#00000042">mdi-alert-octagon</v-icon>
    <BaseTypography class="text-decoration-line-through" variant="subtitle-3" style="color: #00000042">
      {{ equipment.name }}
    </BaseTypography>
  </div>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';

export default {
  name: 'EquipmentElement',
  components: {
    BaseTypography,
  },
  props: {
    equipment: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
