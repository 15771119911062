import * as types from '@/core/store/modules/types/fuel-card-mutation.types';
import FuelCardService from '@/service/FuelCardService';

export default {
  namespaced: true,
  state: {
    fuelCompanies: [],
    isFuelCompaniesLoading: false,
    fuelCards: [],
    isFuelCardsLoading: false,
    totalFuelCardsCount: 0,
    isFuelCardsPaginationLoading: false,
  },
  getters: {
    isFuelCompaniesLoading: (state) => state.isFuelCompaniesLoading,
    fuelCompanies: (state) => state.fuelCompanies,
    fuelCards: (state) => state.fuelCards,
    isFuelCardsLoading: (state) => state.isFuelCardsLoading,
    isFuelCardsEmpty: (state) => state.fuelCards.length === 0,
    fuelCardsAvailableForAssigning: (_, getters) => getters.fuelCards.filter((v) => v.car === null),
    totalFuelCardsCount: (state) => state.totalFuelCardsCount,
    isFuelCardsPaginationLoading: (state) => state.isFuelCardsPaginationLoading,
  },
  mutations: {
    [types.SET_FUEL_COMPANIES_LOADING]: (state, val) => {
      state.isFuelCompaniesLoading = val;
    },
    [types.SET_FUEL_COMPANIES]: (state, val) => {
      state.fuelCompanies = val;
    },
    [types.SET_FUEL_CARDS_LOADING]: (state, val) => {
      state.isFuelCardsLoading = val;
    },
    [types.SET_FUEL_CARDS]: (state, val) => {
      state.fuelCards = val;
    },
    [types.ADD_FUEL_CARD]: (state, val) => {
      state.fuelCards.push(val);
    },
    [types.UPDATE_FUEL_CARD]: (state, val) => {
      const fuelCardIdx = state.fuelCards.findIndex((v) => v.id === val.id);
      state.fuelCards.splice(fuelCardIdx, 1, val);
    },
    [types.SET_TOTAL_FUEL_COMPANIES_COUNT]: (state, val) => {
      state.totalFuelCardsCount = val;
    },
    [types.SET_FUEL_CARDS_PAGINATION_LOADING]: (state, val) => {
      state.isFuelCardsPaginationLoading = val;
    },
  },
  actions: {
    fetchFuelCompanies({ commit }) {
      commit(types.SET_FUEL_COMPANIES_LOADING, true);
      FuelCardService.fetchFuelCompanies()
        .then((data) => {
          commit(types.SET_FUEL_COMPANIES, data);
        })
        .finally(() => {
          commit(types.SET_FUEL_COMPANIES_LOADING, false);
        });
    },
    createFuelCard({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return FuelCardService.createFuelCard(payload)
        .then((data) => {
          commit(types.ADD_FUEL_CARD, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    fetchFuelCards({ commit }, { type = 'initial', ...pagination } = {}) {
      const isPaginationLoading = type === 'pagination';
      commit(isPaginationLoading ? types.SET_FUEL_CARDS_PAGINATION_LOADING : types.SET_FUEL_CARDS_LOADING, true);
      FuelCardService.fetchFuelCards(pagination)
        .then(({ items, totalItems }) => {
          commit(types.SET_FUEL_CARDS, items);
          commit(types.SET_TOTAL_FUEL_COMPANIES_COUNT, totalItems);
        })
        .finally(() => {
          commit(isPaginationLoading ? types.SET_FUEL_CARDS_PAGINATION_LOADING : types.SET_FUEL_CARDS_LOADING, false);
        });
    },

    updateFuelCard({ commit }, val) {
      commit(types.UPDATE_FUEL_CARD, val);
    },
  },
};
