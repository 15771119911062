<template>
  <component :is="tag" :class="className">
    <slot></slot>
  </component>
</template>

<script>
const supportedVariants = {
  h4: { tag: 'h4', class: 'title-h4' },
  h5: { tag: 'h5', class: 'title-h5' },
  subtitle: { tag: 'p', class: 'subtitle' },
  'subtitle-2': { tag: 'p', class: 'base-typography__subtitle-2' },
  'subtitle-3': { tag: 'span', class: 'base-typography__subtitle-3' },
  'body-1': { tag: 'p', class: 'base-typography__body-1' },
};
export default {
  name: 'BaseTypography',
  props: {
    variant: {
      type: String,
      required: true,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    regular: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },
  created() {
    if (!Object.keys(supportedVariants).includes(this.variant)) {
      throw new Error(`Variant ${this.variant} is unsupported variant, use one of ${supportedVariants.join(',')}`);
    }
  },
  computed: {
    className() {
      return [
        supportedVariants[this.variant].class,
        { 'ma-0': this.noMargin },
        { 'font-weight-regular': this.regular },
        { 'font-weight-bold': this.bold },
        this.color && `${this.color}--text`,
      ];
    },
    tag() {
      return supportedVariants[this.variant].tag;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/core/style/variables';

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-h4 {
  font: 500 20px/28px $base-font;
  letter-spacing: 0.15px;
  color: $text-color--primary;
}

.title-h5 {
  font: 500 20px/32px $base-font;
  margin: 16px 0;
  letter-spacing: 0.15px;
  color: $text-color--primary;

  @include textEllipsis();
}

.subtitle {
  font: 500 16px/40px $base-font;
  letter-spacing: 0.25px;
  // TODO: what should be the name of the color?
  color: $text-color--primary;
  margin: 0;
}

.base-typography__subtitle-2 {
  font: 600 14px/18px $base-font;
  color: $text-color--secondary;
  margin-bottom: 0;
}

.base-typography__subtitle-3 {
  font: 400 14px/18px $base-font;
  color: $text-color--primary;
  margin-bottom: 0;
}

.base-typography__body-1 {
  font: 400 16px/24px $base-font;
  letter-spacing: 0.15px;
  color: $text-color--secondary;
}
</style>
