<template>
  <component
    :is="currentComponent"
    :value="isModalOpen"
    :carEntity="carEntity"
    :strategy="strategy"
    v-on="$listeners"
  ></component>
</template>

<script>
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import { CreateCarStrategy } from '../strategy/create-car.strategy';
import { CreateCarForDriverStrategy } from '../strategy/create-car-for-driver.strategy';
import CarPreviewModal from './car-preview-modal';
import CreateCarForPassengerModal from './create-car-for-passenger-modal';
import CreateCarForDriverModal from './create-car-for-driver-modal';

export default {
  name: 'CarModalFactory',
  props: {
    state: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
    },
    isCarFleet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentComponent() {
      if (this.isEditModal) {
        return CarPreviewModal;
      }

      return this.isCarFleet ? CreateCarForDriverModal : CreateCarForPassengerModal;
    },
    isModalOpen() {
      return this.state !== MODAL_STATE.CLOSED;
    },
    isEditModal() {
      return this.state.type === MODAL_STATE.EDIT_ENTITY_MODAL.type;
    },
    carEntity() {
      return this.isEditModal
        ? this.state.selectedEntity
        : {
            brand: null,
            model: null,
            registrationNumber: null,
            vin: null,
            engineCapacity: null,
            initialMileage: null,
            user: null,
          };
    },
    strategy() {
      if (this.isEditModal) {
        return null;
      }

      return this.isCarFleet
        ? new CreateCarForDriverStrategy(this.$store)
        : new CreateCarStrategy({ store: this.$store, user: this.entity.user });
    },
  },
};
</script>

<style lang="scss" scoped></style>
