<template>
  <div>
    <template v-if="readonly">
      <span :data-testid="chipTestId">{{ value }}</span>
    </template>
    <v-chip
      v-else
      :disabled="disabled"
      color="rgba(0, 125, 255, 0.12)"
      small
      text-color="black"
      :data-testid="chipTestId"
    >
      <span>{{ value }}</span>
      <v-btn class="ml-2" icon x-small :aria-label="ariaEditLabel" @click="openEditNumberModal">
        <v-icon color="primary" small>mdi-pencil</v-icon>
      </v-btn>
    </v-chip>
    <v-dialog v-model="dialog" max-width="518px" persistent>
      <v-card>
        <v-card-title>
          <h5 class="text-h5">Edycja</h5>
        </v-card-title>
        <v-card-text>
          <div class="pb-3 pt-3">
            <slot></slot>
          </div>
          <div class="d-flex justify-end">
            <text-button :disabled="isLoading" @click="closeDialog">Anuluj</text-button>
            <text-button :disabled="isLoading" @click="save">Zapisz zmiany</text-button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TextButton from '@/component/Button/text-button';

export default {
  name: 'InlineEditDialog',
  components: { TextButton },
  data() {
    return { dialog: false };
  },
  props: {
    value: null,
    // TODO: refactor
    update: Function,
    updateFn: Function,
    disabled: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    ariaEditLabel: {
      default: 'edytuj',
    },
    chipTestId: {
      default: 'tekst',
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['taxiCourse/isLoading'];
    },
  },
  methods: {
    save() {
      if (this.update) {
        this.update().then(() => {
          this.closeDialog();
        });
      } else {
        this.updateFn();
        this.closeDialog();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    },
    openEditNumberModal() {
      this.dialog = true;
      this.$emit('open', this.value);
    },
  },
};
</script>
