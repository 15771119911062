import TransferProtocolService from '@/service/TransferProtocolService';
import { TRANSFER_PROTOCOL_STATUS } from '@/core/dict/transfer-protocol-dict';
import * as types from './types/transfer-protocol-types';

export default {
  namespaced: true,
  state: {
    transferProtocols: [],
  },
  getters: {
    currentUserId: (_, _2, _3, rootGetters) => `/api/users/${rootGetters.user.id}`,
    // TODO: think for a better name for this getter
    transferProtocolByUser: (state, getters) =>
      state.transferProtocols.find(
        (v) =>
          v.createdBy['@id'] === getters.currentUserId && v.status === TRANSFER_PROTOCOL_STATUS.WAITING_FOR_ACCEPTANCE
      ),
    transferProtocolForNewOwner: (state, getters) =>
      state.transferProtocols.find(
        (v) => v.driverId === getters.currentUserId && v.status === TRANSFER_PROTOCOL_STATUS.WAITING_FOR_ACCEPTANCE
      ),
    transferProtocolWaitingForValidation: (state, getters) =>
      state.transferProtocols.find(
        (v) =>
          (v.driverId === getters.currentUserId || v.createdBy['@id'] === getters.currentUserId) &&
          v.status === TRANSFER_PROTOCOL_STATUS.WAITING_FOR_VALIDATION
      ),
    hasUserTransferProtocolInPendingStatus: (state, getters) =>
      state.transferProtocols.find(
        (v) =>
          v.createdBy['@id'] === getters.currentUserId &&
          [TRANSFER_PROTOCOL_STATUS.WAITING_FOR_ACCEPTANCE, TRANSFER_PROTOCOL_STATUS.WAITING_FOR_VALIDATION].includes(
            v.status
          )
      ),
  },
  mutations: {
    [types.SET_TRANSFER_PROTOCOLS]: (state, val) => {
      state.transferProtocols = val;
    },
    [types.ADD_TRANSFER_PROTOCOL]: (state, val) => {
      state.transferProtocols.push(val);
    },
    [types.UPDATE_TRANSFER_PROTOCOL]: (state, updatedTransferProtocol) => {
      state.transferProtocols = state.transferProtocols.map((v) =>
        v.id === updatedTransferProtocol.id ? updatedTransferProtocol : v
      );
    },
  },
  actions: {
    create({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return TransferProtocolService.createTransferProtocol(payload)
        .then((v) => {
          commit(types.ADD_TRANSFER_PROTOCOL, v);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    createByFleetUser({ commit, dispatch }, payload) {
      commit('setLoading', true, { root: true });
      return TransferProtocolService.createTransferProtocol(payload)
        .then((data) => {
          dispatch('car/addTransferProtocolInCar', { carId: payload.car.id, transferProtocol: data }, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    updateTransferProtocol({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return TransferProtocolService.updateTransferProtocol(payload)
        .then((v) => {
          commit(types.UPDATE_TRANSFER_PROTOCOL, v);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    fetchTransferProtocols({ commit }) {
      return TransferProtocolService.fetchTransferProtocols().then((data) => {
        commit(types.SET_TRANSFER_PROTOCOLS, data);
      });
    },
    acceptTransferProtocol({ commit }, transferProtocolId) {
      commit('setLoading', true, { root: true });
      return TransferProtocolService.acceptTransferProtocol(transferProtocolId)
        .then((data) => {
          commit(types.UPDATE_TRANSFER_PROTOCOL, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    validateTransferProtocol({ commit, dispatch }, { newStatus, transferProtocol }) {
      commit('setLoading', true, { root: true });
      return TransferProtocolService.validateTransferProtocol({ id: transferProtocol.id, newStatus })
        .then((data) => {
          dispatch(
            'car/updateTransferProtocolInCar',
            { carId: transferProtocol.car.id, transferProtocol: data },
            { root: true }
          );
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
