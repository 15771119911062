// eslint-disable-next-line import/prefer-default-export
export class CreateTransferProtocolStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  save(val) {
    return this.#store.dispatch('transferProtocol/create', val);
  }
}
