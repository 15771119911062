const isInputEmpty = (v) => v === '' || v === null;
const VALID = true;

export default {
  required: (v) => (v !== '' && v !== null) || 'Pole jest wymagane',
  integer: (message) => (v) => /^[0-9]*$/.test(v) || message,
  minValue: (minVal, message) => (v) => v >= minVal || message,
  isPositiveNumber: (message = 'Minimalna kwota stawki to 0 zł') => (val) =>
    Number.isNaN(Number.parseFloat(val))
      ? true
      : (Number.parseFloat(val) >= 0 && !Object.is(Number.parseFloat(val), -0)) || message,
  phoneNumber: (v) => /^\d{9}$/.test(v) || 'Numer telefonu powinien składać się z 9 cyfr',
  optionalPhoneNumber: (v) =>
    // duplication of the email method logic is due to the error that I wasn't able to solve
    // when calling this.phoneNumber() inside of that function the 'this' value was undefined, and not the object itself
    isInputEmpty(v) || /^\d{9}$/.test(v) || 'Numer telefonu powinien składać się z 9 cyfr',
  email: (v) => (v?.length > 0 && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) || 'Niepoprawny format adresu email',
  optionalEmail: (v) =>
    // duplication of the email method logic is due to the error that I wasn't able to solve
    // when calling this.email() inside of that function the 'this' value was undefined, and not the object itself
    isInputEmpty(v) || (v.length > 0 && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) || 'Niepoprawny format adresu email',
  contactCredentials(entity) {
    const isFieldFilled = (v) => v !== null && v !== '';

    return () => {
      const { email } = entity.user;
      const phone = entity.user.phones[0]?.phone;

      return isFieldFilled(email) || isFieldFilled(phone) || 'Należy wprowadzić numer telefonu lub adres e-mail';
    };
  },
  registrationNumber: (v) => /^[A-Z]{1,3}[A-Z0-9]{2,5}$/.test(v) || 'Niepoprawny numer rejestracyjny pojazdu',
  vinNumber: (v) => /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/.test(v) || 'Niepoprawny numer VIN pojazdu',
  initialMileage: (v) =>
    isInputEmpty(v) ? VALID : /^[1-9]\d*$/.test(v) || 'Początkowy stan licznika musi być liczbą większą niż 0',
  engineCapacity: (v) => /^(9\d{2}|[1-9]\d{3})$/.test(v) || 'Minimalna pojemnosc silnika 900 cm3',
};
