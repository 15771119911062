/* eslint-disable import/prefer-default-export */
import { capitalize } from 'lodash';

export class FuelCompanyMapper {
  static toModel(fuelCompanyDTO) {
    return {
      name: capitalize(fuelCompanyDTO),
      value: fuelCompanyDTO,
    };
  }
}
