<template>
  <div class="description__item">
    <BaseTypography variant="subtitle-2">{{ title }}</BaseTypography>
    <slot>
      <BaseTypography variant="subtitle-3">{{ value }}</BaseTypography>
    </slot>
  </div>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';

export default {
  name: 'DescriptionItem',
  components: {
    BaseTypography,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.description__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
