export const LOGIN_ROUTE = 'login';
export const LOGOUT_ROUTE = 'logout';
export const UNAUTHORIZED_PASSWORD_RESET_REQUEST_ROUTE = 'UNAUTHORIZED_PASSWORD_RESET_REQUEST_ROUTE';

export const SETTINGS_ROUTE = 'settings';
export const USER_NAME_SETTINGS_ROUTE = 'USER_NAME_SETTINGS_ROUTE';
export const USER_EMAIL_SETTINGS_ROUTE = 'USER_EMAIL_SETTINGS_ROUTE';
export const USER_PASSWORD_CHANGE_ROUTE = 'USER_PASSWORD_CHANGE_ROUTE';
export const USER_PASSWORD_RESET_REQUEST_ROUTE = 'USER_PASSWORD_RESET_REQUEST_ROUTE';
export const USER_PASSWORD_RESET_ROUTE = 'USER_PASSWORD_RESET_ROUTE';
export const GEOLOCATION_SETTINGS_ROUTE = 'GEOLOCATION_SETTINGS_ROUTE';
export const NOTIFICATION_SETTINGS_ROUTE = 'NOTIFICATION_SETTINGS_ROUTE';
export const USER_PHONE_SETTINGS_ROUTE = 'USER_PHONE_SETTINGS_ROUTE';
export const USER_HOMEPLACE_SETTINGS_ROUTE = 'USER_HOMEPLACE_SETTINGS_ROUTE';
export const USER_CAR_SETTINGS_ROUTE = 'USER_CAR_SETTINGS_ROUTE';

export const TAXI_COURSE_LIST_ROUTE = 'taxi_course_list';
export const TAXI_COURSE_CREATE_ROUTE = 'taxi_course_create';
export const TAXI_COURSE_CREATE_INFO = 'taxi_course_info';
export const TAXI_COURSE_NOT_FOUND = 'taxi_course_not_found';

export const KILOMETER_COURSE_CREATE_ROUTE = 'kilometer_course_create';

export const UNPROTECTED_ROUTES = [LOGIN_ROUTE, UNAUTHORIZED_PASSWORD_RESET_REQUEST_ROUTE, USER_PASSWORD_RESET_ROUTE];
