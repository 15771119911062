// eslint-disable-next-line import/prefer-default-export
export class CreateTransferProtocolByFleetStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  save(val) {
    return this.#store.dispatch('transferProtocol/createByFleetUser', val);
  }
}
