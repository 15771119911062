import FleetTransactionsService from '@/service/FleetTransactionsService';
import * as types from './types/fleet-transactions-types';

export default {
  namespaced: true,
  state: {
    transactions: [],
    isTransactionsLoading: false,
    totalTransactionsCount: 0,
    isTransactionsPaginationLoading: false,
  },
  getters: {
    transactions: (state) => state.transactions,
    isTransactionsEmpty: (_, getters) => getters.transactions.length === 0,
    isTransactionsLoading: (state) => state.isTransactionsLoading,
    totalTransactionsCount: (state) => state.totalTransactionsCount,
    isTransactionsPaginationLoading: (state) => state.isTransactionsPaginationLoading,
  },
  mutations: {
    [types.SET_TRANSACTIONS_LOADING]: (state, val) => {
      state.isTransactionsLoading = val;
    },
    [types.SET_TRANSACTIONS]: (state, val) => {
      state.transactions = [...val];
    },
    [types.ADD_TRANSACTIONS]: (state, val) => {
      state.transactions = [...state.transactions, ...val];
    },
    [types.SET_TOTAL_TRANSACTIONS_COUNT]: (state, val) => {
      state.totalTransactionsCount = val;
    },
    [types.SET_TRANSACTIONS_PAGINATION_LOADING]: (state, val) => {
      state.isTransactionsPaginationLoading = val;
    },
    [types.ADD_TOTAL_TRANSACTIONS_COUNT]: (state, val) => {
      state.totalTransactionsCount += val;
    },
  },
  actions: {
    fetchTransactions({ commit }, { type = 'initial', filters = {}, ...pagination }) {
      const isPaginationLoading = type === 'pagination';
      commit(isPaginationLoading ? types.SET_TRANSACTIONS_PAGINATION_LOADING : types.SET_TRANSACTIONS_LOADING, true);
      return FleetTransactionsService.fetchTransactions(pagination, filters)
        .then(({ items, totalItems }) => {
          commit(types.SET_TRANSACTIONS, items);
          commit(types.SET_TOTAL_TRANSACTIONS_COUNT, totalItems);
        })
        .finally(() => {
          commit(
            isPaginationLoading ? types.SET_TRANSACTIONS_PAGINATION_LOADING : types.SET_TRANSACTIONS_LOADING,
            false
          );
        });
    },
    importTransactions({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return FleetTransactionsService.importTransactions(payload)
        .then((val) => {
          commit(types.ADD_TRANSACTIONS, val);
          commit(types.ADD_TOTAL_TRANSACTIONS_COUNT, val.length);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
