<template>
  <v-navigation-drawer
    v-model="isVisible"
    v-resize="onResize"
    class="pl-2 pr-2"
    fixed
    temporary
    :width="drawerWidth"
    stateless
  >
    <v-list-item>
      <div class="w100 d-flex justify-space-between pt-4 pb-6">
        <text-button aria-label="close" color="unset" icon @click="closeDrawer"><v-icon>close</v-icon></text-button>
      </div>
    </v-list-item>
    <abstract-course-filters
      v-if="isVisible"
      :filters="appliedFilters.abstractCourse"
      @input="applyFilters({ abstractCourse: $event })"
    />
    <v-list-item>
      <v-divider />
    </v-list-item>
    <v-list-item class="align-start pt-1">
      <v-btn-toggle
        class="d-flex flex-grow-1 rounded-lg"
        :value="appliedFiltersTab"
        color="primary"
        mandatory
        @change="changeSelectedFilterTab"
      >
        <v-btn
          v-for="tab in tabs"
          :key="tab.name"
          class="font-weight-medium flex-grow-1 pa-4"
          color="primary"
          outlined
          small
        >
          {{ tab.name }}
        </v-btn>
      </v-btn-toggle>
    </v-list-item>
    <v-tabs-items :value="appliedFiltersTab">
      <v-tab-item></v-tab-item>
      <v-tab-item>
        <taxi-course-filters :filters="appliedFilters.taxiCourse" @input="applyFilters({ taxiCourse: $event })" />
      </v-tab-item>
      <v-tab-item>
        <kilometer-course-filters
          :filters="appliedFilters.kilometerCourse"
          :companyId="companyId"
          @input="applyFilters({ kilometerCourse: $event })"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-list-item class="align-start">
      <text-button block color="error" @click="resetFilters">
        <v-icon class="mr-2">mdi-delete</v-icon>
        Wyczyść filtry
      </text-button>
    </v-list-item>
    <template v-slot:append>
      <div class="pa-4">
        <v-btn block color="primary" @click="saveFilters">Zapisz filtry</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import TextButton from '@/component/Button/text-button';
import TaxiCourseFilters from '@/component/NewCourse/Filters/taxi-course-filters';
import KilometerCourseFilters from '@/component/NewCourse/Filters/kilometer-course-filters';
import AbstractCourseFilters from '@/component/NewCourse/Filters/abstract-course-filters';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

const MOBILE_DRAWER_WIDTH = '100vh';
const DESKTOP_DRAWER_WIDTH = '360px';

// TODO: use scss variable in js
const COURSE_MOBILE_BREAKPOINT = 900;

export default {
  name: 'CourseFiltersDrawer',
  components: {
    KilometerCourseFilters,
    TaxiCourseFilters,
    AbstractCourseFilters,
    TextButton,
  },
  props: {
    showDrawer: Boolean,
  },
  data() {
    return {
      // TODO: refactor from localComponent state to manipulating drawer state via props/event
      // currently is impossible to handle v-navigation-drawer without v-model directive
      isVisible: false,
      // ? filterName key was used only for better access of filters -- to use it in Vuex?
      tabs: [
        { name: 'Wszystko', filterName: 'abstractCourse' },
        { name: 'Taxi', filterName: 'taxiCourse' },
        { name: 'Kilometrówka', filterName: 'kilometerCourse' },
      ],
      appliedFilters: null,
      appliedFiltersTab: null,
      filtersUnwatch: null,
      filtersTabUnwatch: null,
      drawerWidth: DESKTOP_DRAWER_WIDTH,
    };
  },
  beforeMount() {
    this.initStoreWatcher();
  },
  destroyed() {
    this.filtersUnwatch();
    this.filtersTabUnwatch();
  },
  computed: {
    ...mapGetters({
      selectedTab: 'taxiCourse/selectedFiltersTab',
      filters: 'taxiCourse/filters',
      isPassenger: 'isPassenger',
    }),
    companyId() {
      return this.$store.getters.user.company?.id;
    },
  },
  methods: {
    changeSelectedFilterTab(val) {
      this.appliedFiltersTab = val;
    },
    applyFilters(val) {
      this.appliedFilters = { ...this.appliedFilters, ...val };
    },
    saveFilters() {
      this.$store.commit('taxiCourse/SAVE_FILTERS', cloneDeep(this.appliedFilters));
      this.$store.commit('taxiCourse/SET_FILTER_TAB', this.appliedFiltersTab);
      this.closeDrawer();
    },
    closeDrawer() {
      this.resetLocalFilters();
      this.$emit('closeDrawer');
    },
    resetFilters() {
      this.$store.commit('taxiCourse/RESET_FILTERS');
      this.resetLocalFilters();
    },
    initStoreWatcher() {
      this.filtersUnwatch = this.$store.watch(
        (_, getters) => getters['taxiCourse/filters'],
        (newVal) => {
          this.appliedFilters = newVal;
        },
        { immediate: true, deep: true }
      );
      this.filtersTabUnwatch = this.$store.watch(
        (_, getters) => getters['taxiCourse/selectedFiltersTab'],
        (newVal) => {
          this.appliedFiltersTab = newVal;
        },
        { immediate: true }
      );
    },
    resetLocalFilters() {
      this.appliedFilters = cloneDeep(this.filters);
      this.appliedFiltersTab = this.selectedTab;
    },
    onResize() {
      this.drawerWidth = window.innerWidth <= COURSE_MOBILE_BREAKPOINT ? MOBILE_DRAWER_WIDTH : DESKTOP_DRAWER_WIDTH;
    },
  },
  watch: {
    showDrawer: {
      handler(val) {
        this.isVisible = val;
      },
      immediate: true,
    },
    isVisible(val) {
      if (val === false) {
        this.closeDrawer();
      }
    },
  },
};
</script>
