<template>
  <AbstractPageToolbar>
    <template #extension>
      <ToolbarSearchField
        class="ml-6"
        style="width: 344px"
        placeholder="Szukaj w samochodach"
        :value="searchTerm"
        @input="fetchFilteredCars"
      ></ToolbarSearchField>
      <v-spacer></v-spacer>
      <BaseTextButton prependIcon="mdi-plus" :color="null" @click="emitEvent">Dodaj samochód</BaseTextButton>
    </template>
  </AbstractPageToolbar>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';
import { eventBus } from '@/core/dict/events-dict';
import debounce from 'lodash/debounce';
import { FILTER_DEBOUNCE_TIME } from '@/core/dict/search-field-dict';
import AbstractPageToolbar from './abstract-page-toolbar';
import ToolbarSearchField from './toolbar-search-field/toolbar-search-field';

export default {
  name: 'CarFleetToolbar',
  components: {
    AbstractPageToolbar,
    ToolbarSearchField,
    BaseTextButton,
  },
  data() {
    return {
      searchTerm: null,
    };
  },
  methods: {
    emitEvent() {
      eventBus.$emit(this.$route.meta.createEvent);
    },
    fetchFilteredCars: debounce(function (searchTerm) {
      this.filterCarsCallback(searchTerm);
    }, FILTER_DEBOUNCE_TIME),
    filterCarsCallback(value) {
      this.searchTerm = value;

      this.$store.dispatch('car/fetchCars', { search: this.searchTerm });
    },
  },
};
</script>

<style lang="scss" scoped></style>
