<template>
  <div>
    <v-alert class="white py-6 px-4 pl-5" border="left" elevation="2" colored-border color="primary">
      <slot name="header" />
      <PlaceFieldFactory
        class="pb-5"
        editable
        :value="segment.waypointStart.place"
        :disabled="!companyId"
        :readonly="readonly"
        @input="calculateRoute"
        @click.native="emitClick"
        data-cy="starting-place-input"
      />
      <taxi-course-segment-kilometer-alert class="mb-5" :segment="segment" @blur="dateChange" />
      <date-time-separate-field :labels="labels" :value="segment.waypointStart.time" @input="dateChange" />
    </v-alert>
    <v-row v-if="showDetails" class="mt-8 px-4">
      <v-col class="col-12 col-sm-6 pt-0">
        <passenger-field-factory
          v-model="segment.employees"
          :company-id="companyId"
          :disabled="!companyId"
          warningColor
          :rules="[(v) => (!!v && v.length > 0) || 'Dodaj przynajmniej jednego pasażera']"
          multiple
        />
      </v-col>
      <v-col class="col-12 col-sm-6 pb-5 pt-0">
        <locomotive-field-factory
          :value="segment.locomotives"
          multiple
          :companyId="companyId"
          :disabled="!companyId"
          @input="locomotiveSelected"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PlaceFieldFactory from '@/component/Field/Place/place-field/place-field-factory';
import TaxiCourseSegmentKilometerAlert from '@/component/NewCourse/Form/Ride/Segment/taxi-course-segment-kilometer-alert';
import DateTimeSeparateField from '@/component/Field/Generic/date-time-separate-field';
import PassengerFieldFactory from '@/component/Field/Passenger/passenger-field-factory';
import LocomotiveFieldFactory from '@/component/Field/Locomotive/locomotive-field-factory';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export default {
  name: 'WaypointForm',
  components: {
    PlaceFieldFactory,
    TaxiCourseSegmentKilometerAlert,
    DateTimeSeparateField,
    PassengerFieldFactory,
    LocomotiveFieldFactory,
  },
  props: {
    segment: { type: Object },
    companyId: {},
    showDetails: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labels: {
        timeLabel: 'Godzina',
        dateLabel: 'Data',
      },
    };
  },
  methods: {
    calculateRoute(place) {
      this.$emit('calculateRoute', place);
    },
    dateChange(v) {
      this.segment.waypointStart.time = v;
      this.$emit('calculateTime');
    },
    locomotiveSelected(locomotives) {
      this.$emit('locomotiveSelected', locomotives);
    },
    emitClick() {
      this.$emit('placeInputClick');
    },
  },
};
</script>
