import Vue from 'vue';
import dayjs from 'dayjs';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { TransactionMapper } from '../shared/mappers/transaction.mapper';

function createFilters(filters) {
  const params = new URLSearchParams();

  Object.keys(filters)
    .filter((key) => !!filters[key])
    .forEach((key) => {
      switch (key) {
        case 'car':
          params.append('car', filters[key].id.split('api/cars/')[1]);
          break;
        case 'driver':
          params.append('driver', filters[key].user.id);
          break;
        case 'fuelCard':
          params.append('fuelCard', filters[key].id);
          break;
        case 'transactionDate':
          params.append('transactionDate[after]', `${dayjs(filters[key].afterDate).format('YYYY-MM-DD')}T00:00:00`);
          params.append('transactionDate[before]', `${dayjs(filters[key].beforeDate).format('YYYY-MM-DD')}T23:59:59`);
          break;
        default:
          break;
      }
    });

  return params;
}

export default {
  fetchTransactions({ page, itemsPerPage }, filters) {
    return Vue.http
      .get(`api/transactions?page=${page}&itemsPerPage=${itemsPerPage}&${createFilters(filters)}`)
      .then(({ data }) => ({
        items: data['hydra:member'].map(TransactionMapper.toModel),
        totalItems: data['hydra:totalItems'],
      }));
  },
  importTransactions(payload) {
    const formData = new FormData();
    formData.append('fuelCardCompany', payload.fuelCompany.value);
    formData.append('file', payload.file);

    return Vue.http
      .post('api/transactions/import', formData, {
        headers: {
          'Content-type': 'multipart/form-data',
          accept: 'application/json',
        },
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Transakcje zostały poprawnie zaimportowane'));
        return data.map(TransactionMapper.toModel);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, data.detail));
        throw new Error(data);
      });
  },
};
