<template>
  <v-menu offset-y v-bind="$attrs">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind:on="on" v-bind:attrs="attrs">
        <v-btn icon v-bind="small ? { ...attrs, ...smallBtnSize } : attrs" :aria-label="ariaLabel" v-on="on">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item v-for="(button, index) in buttons" :key="index" @click="onButtonClick(index)">
          <v-list-item-title>{{ button.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ContextMenu',
  inheritAttrs: false,
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-dots-vertical',
    },
    small: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: 'otwórz kontekstowe menu',
    },
  },
  computed: {
    smallBtnSize() {
      return {
        width: '24',
        height: '24',
      };
    },
  },
  methods: {
    onButtonClick(idx) {
      this.buttons[idx].command.execute();
    },
  },
};
</script>
