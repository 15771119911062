<template>
  <AbstractTransferProtocolModal
    :strategy="strategy"
    :transferProtocol="transferProtocolEntity"
    v-bind="$attrs"
    v-on="$listeners"
  ></AbstractTransferProtocolModal>
</template>

<script>
import AbstractTransferProtocolModal from '../abstract-transfer-protocol-modal';
import { CreateTransferProtocolStrategy } from './create-transfer-protocol.strategy';

export default {
  name: 'CreateTransferProtocolModal',
  components: {
    AbstractTransferProtocolModal,
  },
  props: {
    carEntity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    strategy() {
      return new CreateTransferProtocolStrategy(this.$store);
    },
    transferProtocolEntity() {
      // TODO: probably it would be better to extract this to class
      return {
        driverId: null,
        mileageReading: null,
        comments: null,
        equipment: [],
        transferDate: {
          date: null,
          time: null,
        },
        car: this.carEntity,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
