import Vue from 'vue';
import { FuelCompanyMapper } from '@/shared/mappers/fuel-company.mapper';
import { FuelCardMapper } from '@/shared/mappers/fuel-card.mapper';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

function createPaginationParams(pagination) {
  const params = new URLSearchParams();

  if ('page' in pagination) {
    params.append('page', pagination.page);
    params.append('itemsPerPage', pagination.itemsPerPage);
  } else {
    params.append('pagination', false);
  }

  return params;
}

export default {
  fetchFuelCompanies() {
    return Vue.http.get('api/fuel_companies').then(({ data }) => data.map(FuelCompanyMapper.toModel));
  },
  createFuelCard(payload) {
    return Vue.http
      .post('api/fuel_cards', FuelCardMapper.toDTO(payload))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Karta paliwowa została poprawnie dodana'));
        return FuelCardMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  fetchFuelCards(pagination) {
    return Vue.http.get(`api/fuel_cards?${createPaginationParams(pagination)}`).then(({ data }) => ({
      items: data['hydra:member'].map(FuelCardMapper.toModel),
      totalItems: data['hydra:totalItems'],
    }));
  },
};
