import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { CarMapper } from '@/shared/mappers/car.mapper';
import { CarBrandMapper } from '@/shared/mappers/car-brand.mapper';

const createFilters = (filters) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'search':
          params.append('search', value);
          break;
        default:
          break;
      }
    }
  });

  return params.toString();
};

export default {
  createCar(car) {
    return Vue.http
      .post(`api/cars`, CarMapper.toDTO(car))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Samochód został poprawnie dodany'));
        return CarMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  deleteCar(car) {
    return Vue.http
      .put(car.id, { main: false })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Samochód został usunięty'));
        return CarMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  addFuelCardToCar({ car, fuelCard }) {
    return Vue.http
      .put(car.id, {
        fuelCards: [...car.fuelCards.map((v) => v['@id']), fuelCard['@id']],
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Karta paliwowa została dodana do samochodu'));
        return CarMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  removeFuelCardFromCar({ car, fuelCard }) {
    return Vue.http
      .put(car.id, {
        fuelCards: [...car.fuelCards.filter((v) => v['@id'] !== fuelCard['@id']).map((v) => v['@id'])],
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Karta paliwowa została odpięta do samochodu'));
        return CarMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  fetchCarBrands() {
    return Vue.http.get(`api/brands`).then(({ data }) => data['hydra:member'].map(CarBrandMapper.toModel));
  },
  createBrand(brand) {
    return Vue.http
      .post(`api/brands`, CarBrandMapper.toDTO(brand))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Marka została dodana'));
        return CarBrandMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  updateBrand(brand) {
    return Vue.http
      .put(brand.id, CarBrandMapper.toDTO(brand))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Marka została zapisana'));
        return CarBrandMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data);
      });
  },
  fetchCars(filters = {}) {
    return Vue.http
      .get(`api/cars?${createFilters(filters)}`)
      .then(({ data }) => data['hydra:member'].map((c) => CarMapper.toModel(c)));
  },
};
