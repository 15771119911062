import { capitalize } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export class FuelCardMapper {
  static toDTO(fuelCardModel) {
    return {
      cardNumber: fuelCardModel.cardNumber,
      fuelCardCompany: fuelCardModel.getFuelCompanyId(),
      company: fuelCardModel.getCompanyId(),
    };
  }

  static toModel(fuelCardDTO) {
    return { ...fuelCardDTO, fuelCardCompany: capitalize(fuelCardDTO.fuelCardCompany) };
  }
}
