<template>
  <v-checkbox class="mt-0 py-2" v-bind="$attrs" v-on="$listeners" hide-details="auto">
    <template #label>
      <BaseTypography variant="subtitle-3">{{ label }}</BaseTypography>
    </template>
  </v-checkbox>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';

export default {
  name: 'CheckboxField',
  components: {
    BaseTypography,
  },
  model: {
    prop: 'input-value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
