<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader title="Przekazanie samochodu">
      <template #prepend-item>
        <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
      </template>
    </AbstractModalHeader>
    <v-card-text class="mt-4">
      <AlertInfo class="mb-6">
        Przekazujesz samochód {{ transferProtocolCopy.car.brand }} {{ transferProtocolCopy.car.model }}, o
        <br />
        numerze rejestracyjnym
        <b>{{ transferProtocolCopy.car.registrationNumber }}</b>
      </AlertInfo>

      <v-form ref="form" class="d-flex flex-column" style="row-gap: 16px">
        <BaseTypography variant="subtitle-2">Nowy kierowca</BaseTypography>
        <DriverField
          v-model="transferProtocolCopy.driverId"
          item-value="user.@id"
          :returnObject="false"
          icon="mdi-account"
          :rules="[rules.required]"
        ></DriverField>
        <BaseTypography variant="subtitle-2">Stan samochodu</BaseTypography>
        <!-- TODO: create input with number inputs only -->
        <TextField
          v-model="transferProtocolCopy.mileageReading"
          label="Stan licznika"
          suffix="km"
          hint="Podaj wartość podczas przekazania samochodu"
          :rules="[rules.required, rules.initialMileage]"
        ></TextField>
        <TextField
          v-model="transferProtocolCopy.remarks"
          label="Uwagi"
          hint="Podaj ewentualne uszkodzenia lub nieprawidłowości w samochodzie"
        ></TextField>
        <BaseTypography variant="subtitle-2">Wyposażenie</BaseTypography>
        <div class="d-flex flex-column">
          <CheckboxField
            v-for="(label, value) in EQUIPMENT_DICT"
            v-model="transferProtocolCopy.equipment"
            :key="value"
            :label="label"
            :value="value"
          ></CheckboxField>
        </div>
        <BaseTypography variant="subtitle-2">Data i godzina przekazania</BaseTypography>
        <div class="d-flex" style="column-gap: 16px">
          <!-- TODO: fix styling for those inputs -->
          <DateField
            v-model="transferProtocolCopy.transferDate.date"
            label="Data"
            style="flex-basis: initial"
            :rules="[rules.required]"
            :error-messages="dateValidationErrors"
          ></DateField>
          <TimeField
            v-model="transferProtocolCopy.transferDate.time"
            label="Godzina"
            style="flex-basis: initial"
            :rules="[rules.required]"
          ></TimeField>
        </div>
      </v-form>
    </v-card-text>
    <DialogSaveButton text="Przekaż samochód" @click="saveTransferProtocol"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import AlertInfo from '@/component/Alert/alert-info';
import BaseTypography from '@/component/Base/base-typography';
import DriverField from '@/component/Field/Driver/driver-field';
import TextField from '@/component/Field/Generic/text-field';
import CheckboxField from '@/component/Field/Checkbox/checkbox-field';
import { EQUIPMENT_DICT } from '@/core/dict/transfer-protocol-dict';
import DateField from '@/component/Field/Generic/date-field';
import TimeField from '@/component/Field/Generic/time-field';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import formValidationRules from '@/core/utils/form-validation-rules';
import dayjs from 'dayjs';

export default {
  name: 'AbstractTransferProtocolModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    CloseIconButton,
    AlertInfo,
    BaseTypography,
    DriverField,
    TextField,
    CheckboxField,
    DateField,
    TimeField,
    DialogSaveButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    transferProtocol: {
      type: Object,
      required: true,
    },
    strategy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transferProtocolCopy: JSON.parse(JSON.stringify(this.transferProtocol)),
      rules: formValidationRules,
      dateValidationErrors: [],
    };
  },
  created() {
    this.EQUIPMENT_DICT = EQUIPMENT_DICT;
  },
  computed: {
    isDateInputValid() {
      return this.dateValidationErrors.length === 0;
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    emitCloseEvent() {
      this.$emit('close');
    },
    saveTransferProtocol() {
      if (this.$refs.form.validate() && this.isDateInputValid) {
        this.strategy.save(this.transferProtocolCopy).then(() => {
          this.emitCloseEvent();
        });
      }
    },
    isDateIn12Range() {
      const isDateFieldIsEmpty =
        !this.transferProtocolCopy.transferDate.date || !this.transferProtocolCopy.transferDate.time;

      if (isDateFieldIsEmpty) {
        return true;
      }

      const userDateTime = dayjs(
        `${this.transferProtocolCopy.transferDate.date} ${this.transferProtocolCopy.transferDate.time}`,
        'DD-MM-YYYY HH:mm'
      );

      const currentDateTime = dayjs(this.transferProtocolCopy.createdAt ?? undefined);

      const startTime = currentDateTime.subtract(12, 'hour');
      const endTime = currentDateTime.add(12, 'hour');

      if (userDateTime.isAfter(startTime) && userDateTime.isBefore(endTime)) {
        return true;
      }

      return false;
    },
    validateTransferDate() {
      this.dateValidationErrors = this.isDateIn12Range()
        ? []
        : ['Data i godzina przekazania muszą być w przedziale +/-12h'];
    },
  },
  watch: {
    value() {
      if (this.value === false) {
        this.resetForm();
      } else {
        this.transferProtocolCopy = JSON.parse(JSON.stringify(this.transferProtocol));
      }
    },
    'transferProtocolCopy.transferDate': {
      deep: true,
      handler() {
        this.validateTransferDate();
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
