<template>
  <div class="d-flex flex-column">
    <div
      v-if="!entity.comments.length"
      style="color: rgba(0, 0, 0, 0.38)"
      class="d-flex justify-center align-center flex-grow-1"
      ref="comment-section"
    >
      Brak komentarzy
    </div>
    <div v-else class="d-flex flex-column-reverse overflow-y-auto ml-4 mr-4 mb-2 flex-grow-1">
      <div class="comment-section__wrapper" ref="comment-section">
        <template v-for="comment in entity.comments">
          <div v-if="comment.user.id === $store.getters.user.id" :key="comment.id" class="text-right">
            <ContextMenu :buttons="buttons" v-slot:default="{ on, attrs }">
              <v-chip
                class="ma-1 pa-2 pl-3 pr-3 text-left"
                color="primary rounded-br-0"
                v-on="on"
                v-bind="attrs"
                @click="setMessageId(comment)"
              >
                {{ comment.content }}
              </v-chip>
            </ContextMenu>
            <div class="text-body-2 grey--text pl-2 pr-2">
              {{ comment.createdAt | datetimeHourFirst }}
            </div>
          </div>
          <div v-else :key="comment.id">
            <div class="text-body-2 grey--text pl-2 pr-2">
              {{ comment.user.name }}
            </div>
            <ContextMenu :buttons="buttons" v-slot:default="{ on, attrs }">
              <v-chip
                class="ma-1 pl-3 pr-3 text-left"
                color="white rounded-bl-0"
                v-on="on"
                v-bind="attrs"
                @click="setMessageId(comment)"
              >
                {{ comment.content }}
              </v-chip>
            </ContextMenu>
            <div class="text-body-2 grey--text pl-2 pr-2">
              {{ comment.createdAt | datetimeHourFirst }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div>
      <v-divider />
      <div class="col-12 pa-4 white">
        <v-textarea
          v-model="message"
          :disabled="disabled"
          append-icon="mdi-send"
          auto-grow
          dense
          hide-details
          label="Wiadomość"
          outlined
          rows="1"
          @click:append="sendMessage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ContextMenu from '@/component/ContextMenu/context-menu';
import CommentService from '@/service/CommentService';
import { mapGetters } from 'vuex';
import scrollToBottom from '@/core/mixin/scrollToBottom';
import { RemoveMessageCommand } from './context-menu/remove-comment-command';
import { CopyMessageCommand } from './context-menu/copy-comment-command';

export default {
  name: 'CommentSection',
  components: {
    ContextMenu,
  },
  props: {
    entity: Object,
  },
  mixins: [scrollToBottom('comment-section')],
  data() {
    return {
      message: null,
      disabled: false,
      selectedMessage: null,
    };
  },
  mounted() {
    this.scrollToBottom();
  },
  computed: {
    ...mapGetters(['isAdmin']),
    buttons() {
      return [
        { title: 'Skopiuj komentarz', command: new CopyMessageCommand(this.selectedMessage), isActive: true },
        { title: 'Usuń komentarz', command: new RemoveMessageCommand(this.selectedMessage), isActive: this.isAdmin },
      ].filter((c) => c.isActive);
    },
  },
  methods: {
    sendMessage() {
      if (!this.message.replace(/\s/g, '')) {
        return;
      }

      this.disabled = true;
      CommentService.create(this.entity, this.message, this.$store.getters.user.id)
        .then(() => {
          this.message = null;
          this.scrollToBottom();
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    setMessageId(comment) {
      this.selectedMessage = comment;
    },
  },
};
</script>

<style scoped>
.v-chip {
  white-space: pre-line;
  overflow-wrap: anywhere;
}
.v-chip.v-size--default {
  height: unset;
}

.comment-section__wrapper {
  overflow-y: scroll;
}
</style>
