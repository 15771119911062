import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import FinancialSettlementService from '@/service/FinancialSettlementService';
import * as types from './types/fin-settlement-types';

const DEFAULT_DATE_RANGE = {
  after: {
    date: dayjs().set('date', 1),
    time: dayjs().set('hour', 0).set('minute', 0).set('seconds', 0),
  },
  before: {
    date: dayjs().set('date', dayjs().daysInMonth()),
    time: dayjs().set('hour', 23).set('minute', 59).set('seconds', 59),
  },
};

export default {
  namespaced: true,
  state: {
    dateRange: DEFAULT_DATE_RANGE,
    isLoading: false,
    courses: [],
  },
  getters: {
    dateRange: (state) => state.dateRange,
    isLoading: (state) => state.isLoading,
    courses: (state) => state.courses,
    isCoursesEmpty: (_, getters) => getters.courses.length === 0,
    totalDistance: (_, getters) =>
      getters.courses.reduce((acc, course) => acc + course.rides[0].billToSettled.distance, 0),
    employeeGroup: (_, _2, _3, rootGetters) => rootGetters.user.employeeGroup,
    isStakeForUserDefined: (_, getters) => getters.employeeGroup && getters.employeeGroup.stakes[0] !== undefined,
    kilometerRate(_, getters) {
      if (getters.isStakeForUserDefined) {
        const coinDivider = 100;
        return getters.employeeGroup.stakes[0].kilometerRate / coinDivider;
      }

      return 0;
    },
    totalCost(_, getters) {
      return getters.totalDistance * getters.kilometerRate;
    },
    formattedFilters: (_, getters) => {
      function toDate({ date, time }) {
        return dayjs(date).set('hour', time.hour()).set('minute', time.minute());
      }
      const { after, before } = getters.dateRange;

      const afterFilter = `od ${toDate(after).format('DD.MM.YYYY, HH:mm')}`;
      const beforeFilter = `do ${toDate(before).format('DD.MM.YYYY, HH:mm')}`;

      return {
        afterFilter,
        beforeFilter,
      };
    },
  },
  mutations: {
    [types.SET_LOADING]: (state, val) => {
      state.isLoading = val;
    },
    [types.SET_COURSES]: (state, val) => {
      state.courses = val;
    },
    [types.SET_DATE_RANGE]: (state, val) => {
      state.dateRange = val;
    },
  },
  actions: {
    async init({ commit, dispatch }) {
      commit(types.SET_LOADING, true);
      await dispatch('fetchPassengers', null, { root: true });
      await dispatch('fetchCoursesForSettlement');
    },
    setDateRange({ commit }, val) {
      commit(types.SET_DATE_RANGE, val);
    },
    resetDateToDefault({ commit }) {
      commit(types.SET_DATE_RANGE, cloneDeep(DEFAULT_DATE_RANGE));
    },
    fetchCoursesForSettlement({ commit, getters, rootGetters }) {
      commit(types.SET_LOADING, true);
      FinancialSettlementService.fetchKilometerCoursesForSettlement({
        dateRange: getters.dateRange,
        driverId: rootGetters.passengerId,
      })
        .then((courses) => {
          commit(types.SET_COURSES, courses);
        })
        .finally(() => {
          commit(types.SET_LOADING, false);
        });
    },
    downloadReport({ commit, getters, rootGetters }) {
      commit('setLoading', true);
      FinancialSettlementService.downloadFinSettlementReport({
        dateRange: getters.dateRange,
        driverId: rootGetters.passengerId,
      }).finally(() => {
        commit('setLoading', false);
      });
    },
  },
};
