<template>
  <DescriptionSection>
    <DescriptionItem title="Obsługiwana firma" :value="companyName" />
    <DescriptionItem title="Obsługiwane lokomotywy" :value="locomotives" />
    <DescriptionItem title="Czas na akceptację">
      <inline-edit-dialog
        :update="updateDriverHasToAcceptAt"
        :value="ride.driverHasToAcceptAt ? $options.filters.datetimeHourFirst(ride.driverHasToAcceptAt) : '-'"
        :readonly="isCourseReadonly"
        @open="inlineEditModel = dayjs(ride.driverHasToAcceptAt).format('YYYY-MM-DDTHH:mm')"
      >
        <date-time-field v-model="inlineEditModel" label="Czas na akceptację przejazdu" />
      </inline-edit-dialog>
    </DescriptionItem>
    <DescriptionItem title="Numer przejazdu">
      <inline-edit-dialog
        :update="updateRideNumber"
        :value="ride.number"
        :readonly="isCourseReadonly"
        @open="inlineEditModel = $event"
      >
        <text-field
          v-model="inlineEditModel"
          data-cy="number-input"
          icon="mdi-clipboard-text"
          label="Numer przejazdu"
          required
        />
      </inline-edit-dialog>
    </DescriptionItem>
    <DescriptionItem title="Status">
      <ride-status-chip-field
        v-model="ride.status"
        :disabled="isLoading"
        :statuses="editionRideStatuses"
        :readonly="!isRideStatusEditable"
        @input="updateStatus"
      />
    </DescriptionItem>
    <DescriptionItem title="Dodane" :value="ride.createdAt | createdBy(ride.createdBy)" />
  </DescriptionSection>
</template>

<script>
import dayjs from 'dayjs';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import RideStatusChipField from '@/component/Field/TaxiCourse/ride-status-chip-field';
import InlineEditDialog from '@/component/NewCourse/Show/Details/inline-edit-dialog';
import TextField from '@/component/Field/Generic/text-field';
import DateTimeField from '@/component/Field/Generic/date-time-field';
import { TAXI_COURSE_EDITION_RIDE_DICT, TAXI_COURSE_RIDE_DICT } from '@/core/dict/taxi-course-dict';
import { mapGetters } from 'vuex';
import { UpdateRideStatusCommand } from '@/command/UpdateRideStatusCommand';

const DEFAULT_PLACEHOLDER = '-';

export default {
  name: 'TaxiCourseRideGeneralInfo',
  components: {
    DescriptionSection,
    DescriptionItem,
    TextField,
    InlineEditDialog,
    RideStatusChipField,
    DateTimeField,
  },
  data() {
    return {
      inlineEditModel: null,
    };
  },
  props: {
    ride: Object,
    course: Object,
  },
  methods: {
    dayjs,
    updateDriverHasToAcceptAt() {
      return this.$store.dispatch('taxiCourse/updateValue', {
        '@id': this.ride['@id'],
        value: this.inlineEditModel,
        model: 'driverHasToAcceptAt',
      });
    },
    updateRideNumber() {
      return this.$store.dispatch('taxiCourse/updateValue', {
        '@id': this.ride['@id'],
        model: 'number',
        value: this.inlineEditModel,
      });
    },
    updateStatus() {
      new UpdateRideStatusCommand({ status: this.ride.status, ride: this.ride }).execute();
    },
  },
  computed: {
    ...mapGetters(['isCourseReadonly', 'isManager', 'isLoggedCompanyIsSuperiorContractor']),
    isLoading() {
      return this.$store.getters['taxiCourse/isLoading'];
    },
    isRideStatusEditable() {
      return this.isManager || this.isLoggedCompanyIsSuperiorContractor({ course: this.course, ride: this.ride });
    },
    editionRideStatuses() {
      const statuses = TAXI_COURSE_EDITION_RIDE_DICT.map(([value, text]) => ({ value, text, disabled: false }));
      statuses.push({ value: this.ride.status, text: TAXI_COURSE_RIDE_DICT[this.ride.status], disabled: true });
      return statuses;
    },
    companyName() {
      return this.ride.company.name;
    },
    locomotives() {
      return this.ride.locomotivesSideNumbers || DEFAULT_PLACEHOLDER;
    },
  },
};
</script>
