<template>
  <AbstractPageToolbar>
    <template #extension>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <BaseTextButton
          v-if="!$route.meta.roles || hasRoles($route.meta.roles)"
          :to="$route.meta.create ? { name: $route.meta.create } : null"
          :color="null"
          prependIcon="mdi-plus"
          @click="emitEvent"
        >
          {{ $route.meta.createButtonText || 'Utwórz' }}
        </BaseTextButton>
      </v-toolbar-items>
    </template>
  </AbstractPageToolbar>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';
import intersection from 'lodash/fp/intersection';
import { eventBus } from '@/core/dict/events-dict';
import AbstractPageToolbar from './abstract-page-toolbar';

export default {
  name: 'CreatePageToolbar',
  components: {
    AbstractPageToolbar,
    BaseTextButton,
  },
  methods: {
    hasRoles(roles) {
      return intersection(this.$store.state.user.roles, roles).length > 0;
    },
    emitEvent() {
      if (this.$route.meta.createEvent) {
        eventBus.$emit(this.$route.meta.createEvent);
      }
    },
  },
};
</script>
