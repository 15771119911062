const intFormatter = (val) => (val / 100).toFixed(2);

// eslint-disable-next-line import/prefer-default-export
export class TransactionMapper {
  static toModel(transactionDTO) {
    return {
      ...transactionDTO,
      amount: intFormatter(transactionDTO.amount),
      grossDiscount: intFormatter(transactionDTO.grossDiscount),
      grossValueAfterDiscount: intFormatter(transactionDTO.grossValueAfterDiscount),
      netDiscount: intFormatter(transactionDTO.netDiscount),
      netValueAfterDiscount: intFormatter(transactionDTO.netValueAfterDiscount),
      netValueBeforeDiscount: intFormatter(transactionDTO.netValueBeforeDiscount),
    };
  }
}
