// eslint-disable-next-line import/prefer-default-export
export class UpdateTransferProtocolStrategy {
  #store;

  constructor(val) {
    this.#store = val;
  }

  save(val) {
    return this.#store.dispatch('transferProtocol/updateTransferProtocol', val);
  }
}
