import Vue from 'vue';
import format from 'date-fns/format';
import isValid from 'date-fns/is_valid';
import parse from 'date-fns/parse';

// FIXME: function produce incorrect results for durations >= 24 hours
export default function secondsToTime(ctx) {
  return ctx ? new Date(ctx * 1000).toISOString().substr(11, 8) : null;
}

/** Return Formated Date Or Null */
function formatOrNull(string, dateFormat) {
  if (string) {
    const date = parse(string);
    if (isValid(date)) {
      return format(date, dateFormat);
    }
  }

  return null;
}

export function formatDate(date) {
  return formatOrNull(date, 'DD.MM.YYYY');
}

Vue.filter('date', (ctx) => formatOrNull(ctx, 'DD.MM.YYYY'));

/** Get Datetime Or Null */
Vue.filter('datetime', (ctx) => formatOrNull(ctx, 'YYYY-MM-DD HH:mm'));

/** Get Datetime Or Null */
Vue.filter('datetimeHourFirst', (ctx) => (ctx == null ? '-' : formatOrNull(ctx, 'HH:mm, DD.MM.YYYY')));

/** Get Time Or Null */
Vue.filter('time', (ctx) => formatOrNull(ctx, 'HH:mm'));
Vue.filter('timeWithSeconds', (ctx) => formatOrNull(ctx, 'HH:mm:ss'));

Vue.filter('secondsToTime', (ctx) => secondsToTime(ctx));

Vue.filter('secondsToHumanString', (ctx) => {
  const timeArray = secondsToTime(ctx)?.split(':');
  if (!timeArray) {
    return null;
  }

  return `${+timeArray[0] ? `${+timeArray[0]} godz.` : ''} ${+timeArray[1] ? `${+timeArray[1]} min.` : ''}`;
});

Vue.filter('currency', (ctx) => `${ctx / 100} zł`);

Vue.filter('createdBy', (val, createdBy) => `${formatOrNull(val, 'HH:mm, DD.MM.YYYY')} przez ${createdBy.name}`);

Vue.filter('createdByName', (val) => (val == null ? '-' : val.name));
