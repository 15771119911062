<template>
  <autocomplete-field
    item-text="name"
    :icon="icon"
    label="Kierowca"
    :items="apiDrivers"
    :value="value"
    @input="emitChange"
    :multiple="multiple"
    :required="required"
    :disabled="disabled"
    dense-outlined
    :loading="driversLoading"
    :rules="rules"
    v-bind="$attrs"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </autocomplete-field>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import { mapGetters } from 'vuex';

export default {
  name: 'DriverField',
  components: { AutocompleteField },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-car',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      default: null,
    },
    value: {
      required: false,
    },
    rules: {
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('driver', ['drivers', 'driversLoading']),
    apiDrivers() {
      // ? does it possible to go false branch
      return this.companyId
        ? this.drivers?.filter((driver) => driver.transportCompany?.id === this.companyId)
        : this.drivers;
    },
  },
  created() {
    if (!this.apiDrivers.length) {
      this.$store.dispatch('driver/fetchDrivers');
    }
  },
  methods: {
    emitChange(company) {
      this.$emit('input', company);
    },
  },
};
</script>
