<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader title="Samochód">
      <template #prepend-item>
        <BackIconButton @click="$emit('close')"></BackIconButton>
      </template>
      <template v-if="!hasUserTransferProtocolInPendingStatus" #close>
        <ContextMenu left :buttons="buttons"></ContextMenu>
      </template>
    </AbstractModalHeader>

    <v-card-text>
      <template v-if="hasTransferProtocolRemarks">
        <BaseTypography class="mb-4" variant="subtitle-2">Uwagi do samochodu</BaseTypography>
        <AlertWarning>{{ currentTransferProtocol.remarks }}</AlertWarning>
      </template>
      <DescriptionSection class="mt-6">
        <DescriptionItem title="Numer rejestracyjny" :value="carEntity.registrationNumber" />
        <DescriptionItem title="Marka" :value="carEntity.brand" />
        <DescriptionItem title="Model" :value="carEntity.model" />
        <DescriptionItem title="Numer VIN" :value="carEntity.vin" />
        <DescriptionItem title="Stan licznika" :value="mileageReading | mileageFormatter" />
        <DescriptionItem title="Pojemność silnika" :value="carEntity.engineCapacity | engineCapacityFormatter" />
        <DescriptionItem title="Firma zadrządzająca" :value="carEntity.company" />
        <template v-if="isTransferProtocolDefined">
          <DescriptionItem
            title="Data przekazania"
            :value="currentTransferProtocol.transferDate.full | datetimeHourFirst"
          />
          <div>
            <BaseTypography class="mb-4" variant="subtitle-2">Wyposażenie</BaseTypography>
            <div class="equipment__container">
              <EquipmentElement
                v-for="equipment in equipments"
                :key="equipment.name"
                :equipment="equipment"
              ></EquipmentElement>
            </div>
          </div>
        </template>
      </DescriptionSection>
    </v-card-text>

    <TransferProtocolModalFactory
      type="create"
      :value="transferProtocolModal"
      :carEntity="carEntity"
      @close="closeTransferProtocolModal"
    ></TransferProtocolModalFactory>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import BaseTypography from '@/component/Base/base-typography';
import AlertWarning from '@/component/Alert/alert-warning';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import EquipmentElement from '@/component/Field/Passenger/Car/car-modal/equipment-element/equipment-element';
import ContextMenu from '@/component/ContextMenu/context-menu';
import TransferProtocolModalFactory from '@/controller/Fleet/transfer-protocol/transfer-protocol-modal-factory';
import { mapGetters } from 'vuex';
import { EQUIPMENT_DICT } from '@/core/dict/transfer-protocol-dict';

export default {
  name: 'CarSettingPreviewModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    BackIconButton,
    BaseTypography,
    AlertWarning,
    DescriptionSection,
    DescriptionItem,
    EquipmentElement,
    ContextMenu,
    TransferProtocolModalFactory,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    carEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transferProtocolModal: false,
    };
  },
  computed: {
    ...mapGetters('transferProtocol', ['hasUserTransferProtocolInPendingStatus']),
    isTransferProtocolDefined() {
      return this.carEntity.transferProtocols.length > 0;
    },
    hasTransferProtocolRemarks() {
      return this.currentTransferProtocol && this.currentTransferProtocol.remarks;
    },
    currentTransferProtocol() {
      return this.carEntity.transferProtocols[0];
    },
    mileageReading() {
      return this.carEntity.mileageReadings[0]?.reading;
    },
    buttons() {
      return [
        {
          title: 'Przekaż samochód',
          command: this.openTransferProtocolCommand(),
        },
      ];
    },
    equipments() {
      return Object.keys(EQUIPMENT_DICT).map((key) => ({
        name: EQUIPMENT_DICT[key],
        value: this.currentTransferProtocol.equipment.includes(key),
      }));
    },
  },
  methods: {
    openTransferProtocolModal() {
      this.transferProtocolModal = true;
    },
    closeTransferProtocolModal() {
      this.transferProtocolModal = false;
    },
    openTransferProtocolCommand() {
      return {
        execute: () => {
          this.openTransferProtocolModal();
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO: those styles are repeated across multiple components
.equipment__container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;

  // TODO: when duplication is removed, make flex-basis 50% on mobile and 33% on desktop
  * {
    flex-basis: 50%;
  }
}
</style>
