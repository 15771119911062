import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export class TransferProtocolMapper {
  static toModel(transferProtocolDTO) {
    return {
      id: transferProtocolDTO.id,
      createdBy: transferProtocolDTO.createdBy,
      // added from fleet page
      createdAt: transferProtocolDTO.createdAt,
      acceptedAt: transferProtocolDTO.transferAcceptedAt,
      newDriver: transferProtocolDTO.newDriver,
      // end
      validatedAt: transferProtocolDTO.validatedAt,
      validatedBy: transferProtocolDTO.validatedBy,
      driverId: transferProtocolDTO.newDriver ? transferProtocolDTO.newDriver['@id'] : null,
      mileageReading: transferProtocolDTO.mileageReading,
      remarks: transferProtocolDTO.remarks,
      equipment: transferProtocolDTO.equipment.map((v) => Object.keys(v)[0]),
      transferDate: {
        date: dayjs(transferProtocolDTO.transferDate).format('YYYY-MM-DD'),
        time: dayjs(transferProtocolDTO.transferDate).format('HH:mm'),
        full: transferProtocolDTO.transferDate,
      },
      status: transferProtocolDTO.status,
      // TODO: it's a temporary solution, because structure of car in transfer protocol is incompatible with other structures
      car: {
        brand: transferProtocolDTO.car?.brand,
        model: transferProtocolDTO.car?.model,
        registrationNumber: transferProtocolDTO.car?.registrationNumber,
        vin: transferProtocolDTO.car?.vin,
        engineCapacity: transferProtocolDTO.car?.engineCapacity,
        company: transferProtocolDTO?.car?.company?.name,
        id: transferProtocolDTO?.car?.id,
      },
      // car: CarMapper.toModel(transferProtocolDTO.car),
    };
  }

  static toDTO(transferProtocolModel) {
    const { transferDate } = transferProtocolModel;
    return {
      newDriver: transferProtocolModel.driverId,
      mileageReading: transferProtocolModel.mileageReading,
      remarks: transferProtocolModel.remarks,
      equipment: transferProtocolModel.equipment.map((equipmentName) => ({ [equipmentName]: true })),
      transferDate: `${transferDate.date}T${transferDate.time}`,
      car: transferProtocolModel.car.id.startsWith('api/cars')
        ? transferProtocolModel.car.id
        : `api/cars/${transferProtocolModel.car.id}`,
      transferAcceptedAt: transferProtocolModel.acceptedAt,
    };
  }
}
