<template>
  <ContextMenu left :buttons="buttons" :icon="menuActivatorIcon" v-bind="$attrs"></ContextMenu>
</template>

<script>
import ContextMenu from '@/component/ContextMenu/context-menu';

export default {
  name: 'RideContextMenu',
  components: {
    ContextMenu,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menuActivatorIcon() {
      return this.vertical ? 'mdi-dots-vertical' : 'mdi-dots-horizontal';
    },
  },
};
</script>
