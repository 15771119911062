import Vue from 'vue';
import download from 'downloadjs';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { KILOMETER_COURSE_STATUS } from '@/core/dict/kilometer-course-dict';
import { DateFormatter } from '@/core/utils/dateFormatter';

const getSearchParams = ({ dateRange, driverId }) => {
  const params = {
    pagination: false,
    'rides.status[eq]': KILOMETER_COURSE_STATUS.TO_BE_SETTLED,
    'firstPlaceDateStart[after]': DateFormatter.formatDate(dateRange.after),
    'firstPlaceDateStart[before]': DateFormatter.formatDate(dateRange.before),
    kilometerCourseDriver: driverId,
  };

  return new URLSearchParams(params).toString();
};

export default {
  fetchKilometerCoursesForSettlement(payload) {
    return Vue.http.get(`api/abstract_courses?${getSearchParams(payload)}`).then(({ data }) => data['hydra:member']);
  },
  downloadFinSettlementReport(payload) {
    return Vue.http
      .get(`api/kilometer_courses/report?${getSearchParams(payload)}`, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      })
      .then(({ data }) => download(data, 'raport.pdf', 'application/pdf'))
      .catch(({ data, status }) => {
        const encodedData = JSON.parse(new TextDecoder('utf-8').decode(data));

        if (status === 400) {
          Vue.notify(defaultApiResultManager.catch(encodedData, status, encodedData.detail));
        } else {
          Vue.notify(
            defaultApiResultManager.catch(
              encodedData,
              status,
              'Wystąpił błąd podczas pobierania raportu. Spróbuj ponownie później'
            )
          );
        }
      });
  },
};
