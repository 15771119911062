import { EVENTS } from '@/core/dict/events-dict';

export const DRIVER_REPORT_PAGE = 'driver-report';
export const CAR_FLEET_PAGE = 'car-fleet';
export const SUBCONTRACTOR_REPORT_PAGE = 'subcontractor-report-page';
export const FUEL_CARDS_PAGE = 'fuel-cards';
export const TRANSACTIONS_PAGE = 'transactions';

export default [
  {
    path: DRIVER_REPORT_PAGE,
    name: DRIVER_REPORT_PAGE,
    component: () => import('@/controller/Fleet/driver-report/driver-report'),
    meta: {
      title: 'Rozliczenia kierowców',
    },
  },
  {
    path: CAR_FLEET_PAGE,
    name: CAR_FLEET_PAGE,
    component: () => import('@/controller/Fleet/car-fleet/car-fleet'),
    meta: {
      title: 'Samochody',
      createEvent: EVENTS.OPEN_CREATE_MODAL,
    },
  },
  {
    path: SUBCONTRACTOR_REPORT_PAGE,
    name: SUBCONTRACTOR_REPORT_PAGE,
    component: () => import('@/controller/Fleet/subcontractor-report/subcontractor-report'),
    meta: {
      title: 'Rozliczenia podwykonawców',
    },
  },
  {
    path: FUEL_CARDS_PAGE,
    name: FUEL_CARDS_PAGE,
    component: () => import('@/controller/Fleet/fuel-cards/fuel-cards'),
    meta: {
      title: 'Karty paliwowe',
      createEvent: EVENTS.OPEN_CREATE_MODAL,
    },
  },
  {
    path: TRANSACTIONS_PAGE,
    name: TRANSACTIONS_PAGE,
    component: () => import('@/controller/Fleet/transactions/transactions-page'),
    meta: {
      title: 'Transakcje',
      createEvent: EVENTS.OPEN_CREATE_MODAL,
      createButtonText: 'Import transakcji',
    },
  },
];
