import Vue from 'vue';
import { TransferProtocolMapper } from '@/shared/mappers/transfer-protocol.mapper';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { TRANSFER_PROTOCOL_STATUS } from '@/core/dict/transfer-protocol-dict';

export default {
  createTransferProtocol(payload) {
    return Vue.http
      .post('api/transfer_protocols', TransferProtocolMapper.toDTO(payload))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Samochód został poprawnie przekazany'));
        return TransferProtocolMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },
  updateTransferProtocol(payload) {
    return Vue.http
      .put(`api/transfer_protocols/${payload.id}`, TransferProtocolMapper.toDTO(payload))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Protokól przekazania samochodu został zaktualizowany'));
        return TransferProtocolMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },
  fetchTransferProtocols() {
    return Vue.http
      .get(
        `api/transfer_protocols?status[]=${TRANSFER_PROTOCOL_STATUS.WAITING_FOR_ACCEPTANCE}&status[]=${TRANSFER_PROTOCOL_STATUS.WAITING_FOR_VALIDATION}`
      )
      .then(({ data }) => data['hydra:member'].map(TransferProtocolMapper.toModel));
  },
  acceptTransferProtocol(id) {
    return Vue.http
      .put(`api/transfer_protocols/${id}/accept`, { status: TRANSFER_PROTOCOL_STATUS.ACCEPTED })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Samochód został przejęty'));
        return TransferProtocolMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },
  validateTransferProtocol({ id, newStatus }) {
    return Vue.http
      .put(`api/transfer_protocols/${id}/validate`, { status: newStatus })
      .then(({ data }) => {
        Vue.notify(
          defaultApiResultManager.then(
            `Protokół przekazania został ${
              newStatus === TRANSFER_PROTOCOL_STATUS.ACCEPTED ? 'zaakceptowany' : 'odrzucony'
            }`
          )
        );
        return TransferProtocolMapper.toModel(data);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },
};
