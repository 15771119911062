<template>
  <AbstractTransferProtocolModal
    :strategy="strategy"
    :transferProtocol="transferProtocol"
    v-bind="$attrs"
    v-on="$listeners"
  ></AbstractTransferProtocolModal>
</template>

<script>
import AbstractTransferProtocolModal from '../abstract-transfer-protocol-modal';
import { UpdateTransferProtocolStrategy } from './update-transfer-protocol.strategy';

export default {
  name: 'EditTransferProtocolModal',
  components: {
    AbstractTransferProtocolModal,
  },
  props: {
    transferProtocol: {
      type: Object,
      required: true,
    },
  },
  computed: {
    strategy() {
      return new UpdateTransferProtocolStrategy(this.$store);
    },
    transferProtocolEntity() {
      return this.transferProtocol;
    },
  },
};
</script>

<style lang="scss" scoped></style>
