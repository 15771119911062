<template>
  <pulse-badge :isVisible="rideHasComments" offset-y="5px" offset-x="5px">
    <v-card @click.stop="clicked" class="d-flex flex-column">
      <v-system-bar :color="statusColor" class="white--text pl-4 text-body-1">
        {{ ride.number }}
        <template v-if="!isCompanyUser">
          <v-icon color="#FFFFFF80" class="pl-1">mdi-circle-medium</v-icon>
          <span>{{ companyName }}</span>
        </template>
        <v-spacer></v-spacer>
        <RideContextMenuFactory v-if="!isCourseForm" :ride="ride" :course="course" small></RideContextMenuFactory>
      </v-system-bar>
      <v-system-bar :color="statusColor" class="white--text pl-4 ride-sub-bar">
        {{ statusName }}
      </v-system-bar>
      <segments-timeline
        class="flex-grow-1"
        :ride="ride"
        :course="course"
        :extended-view="isCourseForm"
        @acceptRide="updateRideStatus"
        @declineRide="updateRideStatus"
        data-testid="segments-timeline"
      />
    </v-card>
  </pulse-badge>
</template>
<script>
import { TAXI_COURSE_RIDE_DICT, TAXI_COURSE_STATUS_COLOR_DICT } from '@/core/dict/taxi-course-dict';
import { KILOMETER_COURSE_COLOR_DICT, KILOMETER_COURSE_DICT } from '@/core/dict/kilometer-course-dict';
import SegmentsTimeline from '@/component/NewCourse/Segments/segments-timeline-factory';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import PulseBadge from '@/component/Base/pulse-badge';
import { TAXI_COURSE_CREATE_ROUTE } from '@/core/router/route-names';
import { mapGetters } from 'vuex';
import RideContextMenuFactory from '@/component/NewCourse/RideContextMenu/ride-context-menu-factory';
import { UpdateRideStatusCommand } from '@/command/UpdateRideStatusCommand';

export default {
  name: 'TaxiCourseRideCard',
  components: {
    SegmentsTimeline,
    PulseBadge,
    RideContextMenuFactory,
  },
  data() {
    return {
      statusDict: TAXI_COURSE_RIDE_DICT,
    };
  },
  props: {
    ride: Object,
    course: Object,
  },
  computed: {
    ...mapGetters(['isCompanyUser']),
    statusColor() {
      switch (this.ride['@type']) {
        case COURSE_TYPE_DICT.KILOMETER_COURSE_RIDE:
          return this.ride.status ? KILOMETER_COURSE_COLOR_DICT[this.ride.status] : 'grey';
        case COURSE_TYPE_DICT.TAXI_COURSE_RIDE:
        default:
          return this.ride.status ? TAXI_COURSE_STATUS_COLOR_DICT[this.ride.status] : 'grey';
      }
    },
    statusName() {
      switch (this.ride['@type']) {
        case COURSE_TYPE_DICT.TAXI_COURSE_RIDE:
          return TAXI_COURSE_RIDE_DICT[this.ride.status];
        case COURSE_TYPE_DICT.KILOMETER_COURSE_RIDE:
          return KILOMETER_COURSE_DICT[this.ride.status];
        default:
          return '-';
      }
    },
    companyName() {
      return this.ride?.company?.name;
    },
    rideHasComments() {
      return this.ride?.comments?.length > 0;
    },
    isCourseForm() {
      return this.$route.name === TAXI_COURSE_CREATE_ROUTE;
    },
  },
  methods: {
    clicked() {
      this.$emit('rideClick', this.ride);
    },
    updateRideStatus(status) {
      new UpdateRideStatusCommand({ status, ride: this.ride }).execute();
    },
  },
};
</script>
<style>
.ride-sub-bar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.25) 0 0);
}
</style>
