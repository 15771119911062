<template>
  <v-app>
    <v-main>
      <CarSettingPreviewModal :value="true" :carEntity="user.car" @close="navigateToSettings"></CarSettingPreviewModal>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import * as ROUTES from '@/core/router/route-names';
import CarSettingPreviewModal from '@/component/Field/Passenger/Car/car-modal/car-setting-preview-modal';

export default {
  name: 'UserCarSettings',
  components: {
    CarSettingPreviewModal,
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTES.SETTINGS_ROUTE });
    },
  },
};
</script>

<style lang="scss" scoped></style>
