<template>
  <AbstractPageToolbar>
    <template #extension>
      <v-spacer></v-spacer>
      <BaseTextButton prependIcon="mdi-plus" :color="null" @click="emitEvent">Dodaj kartę paliwową</BaseTextButton>
    </template>
  </AbstractPageToolbar>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';
import { eventBus } from '@/core/dict/events-dict';
import AbstractPageToolbar from './abstract-page-toolbar';

export default {
  name: 'FuelCardsToolbar',
  components: {
    AbstractPageToolbar,
    BaseTextButton,
  },
  data() {
    return {
      searchTerm: null,
    };
  },
  methods: {
    emitEvent() {
      eventBus.$emit(this.$route.meta.createEvent);
    },
  },
};
</script>

<style lang="scss" scoped></style>
