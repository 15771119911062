import { CarMapper } from './car.mapper';

// eslint-disable-next-line import/prefer-default-export
export class UserMapper {
  static toModel(userDTO) {
    const isCarDefined = userDTO.cars.length && userDTO.cars.find((c) => c.main);

    return {
      ...userDTO,
      car: isCarDefined ? CarMapper.toModel(userDTO.cars.find((c) => c.main)) : null,
      phones: userDTO.phones ? userDTO.phones : [{ '@id': 0, phone: null, main: false }],
    };
  }
}
