<template>
  <ContextMenu left :buttons="$attrs.buttons"></ContextMenu>
</template>

<script>
import ContextMenu from '@/component/ContextMenu/context-menu';

export default {
  name: 'RecentlyUsedPlaceContextMenu',
  components: {
    ContextMenu,
  },
};
</script>
