import Vue from 'vue';

function mileageFormatter(val) {
  return val != null
    ? new Intl.NumberFormat('pl-PL', {
        style: 'unit',
        unit: 'kilometer',
      }).format(val)
    : '-';
}

function engineCapacityFormatter(val) {
  return `${val} cm³`;
}

Vue.filter('mileageFormatter', mileageFormatter);
Vue.filter('engineCapacityFormatter', engineCapacityFormatter);
