<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import CreateTransferProtocolModal from './create-transfer-protocol/create-transfer-protocol-modal';
import EditTransferProtocolModal from './edit-transfer-protocol/edit-transfer-protocol-modal';
import CreateTransferProtocolByFleetModal from './create-transfer-protocol-by-fleet/create-transfer-protocol-by-fleet-modal';

export default {
  name: 'TransferProtocolModalFactory',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentComponent() {
      if (this.type === 'create') {
        return CreateTransferProtocolModal;
      }

      if (this.type === 'create-by-fleet') {
        return CreateTransferProtocolByFleetModal;
      }

      return EditTransferProtocolModal;
    },
  },
};
</script>

<style lang="scss" scoped></style>
