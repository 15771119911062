import CarService from '@/service/CarService';
import * as types from './types/car-mutation-types';

export default {
  namespaced: true,
  state: {
    cars: [],
    isCarsLoading: false,
    brands: [],
    isBrandsLoading: false,
  },
  getters: {
    brands: (state) => state.brands,
    isBrandsLoading: (state) => state.isBrandsLoading,
    cars: (state) => state.cars,
    isCarsLoading: (state) => state.isCarsLoading,
    isCarsEmpty: (_, getters) => getters.cars.length === 0,
  },
  mutations: {
    [types.SET_BRAND_LOADING]: (state, val) => {
      state.isBrandsLoading = val;
    },
    [types.SET_CARS_LOADING]: (state, val) => {
      state.isCarsLoading = val;
    },
    [types.SET_BRANDS]: (state, val) => {
      state.brands = val;
    },
    [types.ADD_BRAND]: (state, val) => {
      const haystackIdx = state.brands.findIndex((b) => b.id === val.id);
      if (haystackIdx !== -1) {
        state.brands.splice(haystackIdx, 1, val);
      } else {
        state.brands.push(val);
      }
    },
    [types.SET_CARS]: (state, val) => {
      state.cars = val;
    },
    [types.ADD_CAR]: (state, val) => {
      state.cars = [...state.cars, val];
    },
    [types.UPDATE_CAR]: (state, { carIdx, val }) => {
      state.cars.splice(carIdx, 1, val);
    },
    [types.REMOVE_CAR]: (state, carId) => {
      state.cars = state.cars.filter((c) => c.id !== carId);
    },
    [types.UPDATE_TRANSFER_PROTOCOL_IN_CAR]: (state, { carId, transferProtocol }) => {
      state.cars = state.cars.map((car) => {
        if (carId === car.id) {
          return {
            ...car,
            transferProtocols: car.transferProtocols.map((tp) =>
              tp.id === transferProtocol.id ? transferProtocol : tp
            ),
          };
        }

        return car;
      });
    },
    [types.ADD_TRANSFER_PROTOCOL_IN_CAR]: (state, { carId, transferProtocol }) => {
      state.cars = state.cars.map((car) => {
        if (carId === car.id) {
          return {
            ...car,
            transferProtocols: [transferProtocol, ...car.transferProtocols],
          };
        }

        return car;
      });
    },
  },
  actions: {
    createCar({ commit }, car) {
      commit('setLoading', true, { root: true });
      return CarService.createCar(car)
        .then((data) => {
          commit(types.ADD_CAR, data);
          return data;
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    deleteCar({ commit }, car) {
      commit('setLoading', true, { root: true });
      return CarService.deleteCar(car)
        .then(() => {
          commit(types.REMOVE_CAR, car.id);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    fetchCarBrands({ commit }) {
      commit(types.SET_BRAND_LOADING, true);
      return CarService.fetchCarBrands()
        .then((data) => {
          commit(types.SET_BRANDS, data);
        })
        .finally(() => {
          commit(types.SET_BRAND_LOADING, false);
        });
    },
    createBrand({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return CarService.createBrand(payload)
        .then((data) => {
          commit(types.ADD_BRAND, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    updateBrand({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return CarService.updateBrand(payload)
        .then((data) => {
          commit(types.ADD_BRAND, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    fetchCars({ commit }, filters) {
      commit(types.SET_CARS_LOADING, true);
      return CarService.fetchCars(filters)
        .then((data) => {
          commit(types.SET_CARS, data);
        })
        .finally(() => {
          commit(types.SET_CARS_LOADING, false);
        });
    },
    updateTransferProtocolInCar({ commit }, payload) {
      commit(types.UPDATE_TRANSFER_PROTOCOL_IN_CAR, payload);
    },
    addTransferProtocolInCar({ commit }, payload) {
      commit(types.ADD_TRANSFER_PROTOCOL_IN_CAR, payload);
    },
    addFuelCardToCar({ commit, dispatch }, { carIdx, ...payload }) {
      commit('setLoading', true, { root: true });
      return CarService.addFuelCardToCar(payload)
        .then((val) => {
          commit(types.UPDATE_CAR, { carIdx, val });
          dispatch(
            'fuelCard/updateFuelCard',
            {
              ...payload.fuelCard,
              car: payload.car,
            },
            { root: true }
          );
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    removeFuelCardFromCar({ commit, dispatch }, { carIdx, ...payload }) {
      commit('setLoading', true, { root: true });
      return CarService.removeFuelCardFromCar(payload)
        .then((val) => {
          commit(types.UPDATE_CAR, { carIdx, val });
          dispatch(
            'fuelCard/updateFuelCard',
            {
              ...payload.fuelCard,
              car: null,
            },
            { root: true }
          );
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
