import Vue from 'vue';
import get from 'lodash/get';
import './datetime-filter';
import './phone-filter';
import './course';
import './car';
import './fleet';

/** Get Value Of Given Context By DotNotated Path Or Null */
Vue.filter('nullable', (context, path) => (path ? get(context, path, null) : context || null));

Vue.filter('pretty_float', (float) =>
  float
    ? float.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : ''
);

Vue.filter('currency', (float) =>
  float !== null
    ? new Intl.NumberFormat('pl', {
        style: 'currency',
        currency: 'PLN',
        trailingZeroDisplay: 'stripIfInteger',
      }).format(float)
    : '-'
);

Vue.filter('placeholder', (val) => {
  const DEFAULT_PLACEHOLDER = '-';
  return val || DEFAULT_PLACEHOLDER;
});
