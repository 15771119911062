export const SET_BRAND_LOADING = 'SET_BRAND_LOADING';
export const SET_BRANDS = 'SET_BRANDS';
export const ADD_BRAND = 'ADD_BRAND';
export const SET_CARS = 'SET_CARS';
export const ADD_CAR = 'ADD_CAR';
export const UPDATE_CAR = 'UPDATE_CAR';
export const REMOVE_CAR = 'REMOVE_CAR';
export const UPDATE_TRANSFER_PROTOCOL_IN_CAR = 'UPDATE_TRANSFER_PROTOCOL_IN_CAR';
export const ADD_TRANSFER_PROTOCOL_IN_CAR = 'ADD_TRANSFER_PROTOCOL_IN_CAR';
export const SET_CARS_LOADING = 'SET_CARS_LOADING';
