export const EQUIPMENT_DICT = {
  CAR_JACK: 'Lewarek',
  WHEEL_WRENCH: 'Klucz do kół',
  SPARE_WHEEL: 'Koło zapasowe',
  FIRE_EXTINGUISHER: 'Gaśnica',
  TRIANGLE: 'Trójkąt',
  AID_KIT: 'Apteczka',
};

export const TRANSFER_PROTOCOL_STATUS = {
  WAITING_FOR_ACCEPTANCE: 'waiting_for_acceptance',
  WAITING_FOR_VALIDATION: 'waiting_for_validation',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
};
